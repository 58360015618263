import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import AssetSelect from './Components/assetselect.js';

import './AccessControl.css';

function AccessControl({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredassets, setFilteredassets] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    const assetTypes = [
        { value: "ble", label: "Bluetooth Low Energy" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.accesscontrol.filter((accesscontrol) =>
            accesscontrol.id.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredassets(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddAccessControl />);
    }

    const editButton = (accesscontrol) => {
        setOption(<EditAccessControl accesscontrol={accesscontrol} />);
    }

    const removeButton = (accesscontrol) => {
        setOption(<RemoveAccessControl accesscontrol={accesscontrol} />);
    }

    const viewButton = (accesscontrol) => {
        setOption(<ViewAccessControl accesscontrol={accesscontrol} />);
    }

    function AddAccessControl() {
        const [assetid, setAssetid] = useState('');
        const [type, setType] = useState('');
        const [uuid, setUuid] = useState('');
        const [suuid, setSuuid] = useState('');
        const [cuuid, setCuuid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (assetTypes.length > 0) {
                setType(assetTypes[0].value);
            }
        }, [assetTypes]);

        const addAccessControl = (assetid, type, uuid, suuid, cuuid) => {
            console.log('Trying to add access control');
            setResult(null)

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Contol', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!assetid || !type || !uuid) {
                setResult('Missing data');
                return;
            }

            const trimmedUUID = uuid.trim();
            const trimmedSUUID = suuid.trim();
            const trimmedCUUID = cuuid.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                assetid: assetid,
                type: type,
                uuid: trimmedUUID,
                suuid: trimmedSUUID,
                cuuid: trimmedCUUID
            }

            axios.post(configData.CONTROLLERURL + configData.ADDACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ACCESSCONTROL',
                            accesscontrol: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }


        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Access Control</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {assetTypes.map((asset, index) => (
                                        <option key={index} value={asset.value}>
                                            {asset.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="UUID"
                                    value={uuid}
                                    onChange={(e) => setUuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Service UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Service UUID"
                                    value={suuid}
                                    onChange={(e) => setSuuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Characteristic UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Characteristic UUID"
                                    value={cuuid}
                                    onChange={(e) => setCuuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccessControl(assetid, type, uuid, suuid, cuuid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccessControl({ accesscontrol }) {
        const [id, setId] = useState();
        const [assetid, setAssetid] = useState('');
        const [type, setType] = useState('');
        const [uuid, setUuid] = useState('');
        const [suuid, setSuuid] = useState('');
        const [cuuid, setCuuid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(accesscontrol.id)
            setAssetid(accesscontrol.assetid)
            setType(accesscontrol.type)
            setUuid(accesscontrol.uuid)
            setSuuid(accesscontrol.suuid)
            setCuuid(accesscontrol.cuuid)
        }, [accesscontrol]);

        const updateAccessControl = (id, assetid, type, uuid, suuid, cuuid) => {
            console.log('Trying to update access control');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Control', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!assetid, !type, !uuid) {
                setResult('Missing data');
                return;
            }

            const trimmedUUID = uuid.trim();
            const trimmedSUUID = suuid.trim();
            const trimmedCUUID = cuuid.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                assetid: assetid,
                type: type,
                uuid: trimmedUUID,
                suuid: trimmedSUUID,
                cuuid: trimmedCUUID
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ACCESSCONTROL',
                            accesscontrol: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Access Control</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {assetTypes.map((asset, index) => (
                                        <option key={index} value={asset.value}>
                                            {asset.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="UUID"
                                    value={uuid}
                                    onChange={(e) => setUuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Service UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Service UUID"
                                    value={suuid}
                                    onChange={(e) => setSuuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Characteristic UUID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Characteristic UUID"
                                    value={cuuid}
                                    onChange={(e) => setCuuid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAccessControl(id, assetid, type, uuid, suuid, cuuid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(accesscontrol)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccessControl({ accesscontrol }) {
        const [id, setId] = useState(accesscontrol.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeAsset = (id) => {
            console.log('Trying to remove access control');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Access Control', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEACCESSCONTROL, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove access control data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ACCESSCONTROL',
                            accesscontrolid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAsset(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAccessControl({ accesscontrol }) {
        const [id, setId] = useState(accesscontrol.id);

        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Access Control</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{id}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='accesscontrol'>
            <Title text='Access Control' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Access Control</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>AssetName</th>
                        <th>Type</th>
                        <th>UUID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredassets.map((accesscontrol, index) => (
                            <tr key={'accesscontrol' + index}>
                                <td><div className='table_button' onClick={() => editButton(accesscontrol)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(accesscontrol)}>View</div></td>
                                <td><div>{accesscontrol.id}</div></td>
                                <td><div>{project.assets.find((asset) => asset.id === accesscontrol.assetid)?.name}</div></td>
                                <td><div>{accesscontrol.type}</div></td>
                                <td><div>{accesscontrol.uuid}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default AccessControl;