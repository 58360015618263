import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';

import { getChartAccounts } from './Utils/chartaccounts.js';
import Title from './Title.js';
import { transactionsremovalprotection, journalentryremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';

import CurrencySelect from './Components/currencyselect.js';

import './ChartAccounts.css';

function ChartAccounts({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    const chartaccountsdata = getChartAccounts(project.incomegroups, project.incomeaccounts, project.expensegroups, project.expenseaccounts, project.currencies);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null)
    }

    const addButtonGroup = () => {
        setOption(<AddGroup />)
    }

    const editButtonGroup = (group) => {
        setOption(<EditGroup group={group} />)
    }

    const removeButtonGroup = (group) => {
        setOption(<RemoveGroup group={group} />)
    }

    const addButtonAccount = () => {
        setOption(<AddAccount />)
    }

    const editButtonAccount = (account) => {
        setOption(<EditAccount account={account} />)
    }

    const removeButtonAccount = (account) => {
        setOption(<RemoveAccount account={account} />)
    }

    const exportPage = () => {
        setOption(<ExportPage />)
    }

    function ExportPage() {
        useEffect(() => {
            const dataToCopy = {
                incomegroups: project.incomegroups,
                incomeaccounts: project.incomeaccounts,
                expensegroups: project.expensegroups,
                expenseaccounts: project.expenseaccounts
            };

            const dataString = JSON.stringify(dataToCopy, null, 2);

            navigator.clipboard.writeText(dataString)
                .then(() => console.log('Data copied to clipboard'))
                .catch(err => console.error('Error copying data to clipboard:', err));
        }, []);

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Chart Exported</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const importPage = () => {
        setOption(<ImportPage />)
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [result, setResult] = useState('');

        const handleImport = () => {
            let importedData = JSON.parse(importText);

            if (!(importedData.incomegroups instanceof Array) ||
                !(importedData.incomeaccounts instanceof Array) ||
                !(importedData.expensegroups instanceof Array) ||
                !(importedData.expenseaccounts instanceof Array)) {
                setResult('Invalid data structure');
                return;
            }

            const checkDuplicates = (projectItems, importedItems, itemType) => {
                const projectIds = new Set(projectItems.map(item => item.id));
                const projectNames = new Set(projectItems.map(item => item.name));

                const importedIds = new Set(importedItems.map(item => item.id));
                const importedNames = new Set(importedItems.map(item => item.name));

                const duplicateIds = [...importedIds].filter(id => projectIds.has(id));
                const duplicateNames = [...importedNames].filter(name => projectNames.has(name));

                if (duplicateIds.length > 0 || duplicateNames.length > 0) {
                    setResult(`Duplicate ${itemType} IDs or names found.`);
                    return true;
                }
                return false;
            };

            if (checkDuplicates(project.incomegroups, importedData.incomegroups, 'income group')) return;
            if (checkDuplicates(project.incomeaccounts, importedData.incomeaccounts, 'income account')) return;
            if (checkDuplicates(project.expensegroups, importedData.expensegroups, 'expense group')) return;
            if (checkDuplicates(project.expenseaccounts, importedData.expenseaccounts, 'expense account')) return;

            console.log('Trying to import chart');
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                incomegroups: importedData.incomegroups,
                incomeaccounts: importedData.incomeaccounts,
                expensegroups: importedData.expensegroups,
                expenseaccounts: importedData.expenseaccounts,
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCHARTACCOUNTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import chart data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CHARTACCOUNTS',
                            incomegroups: importedData.incomegroups,
                            incomeaccounts: importedData.incomeaccounts,
                            expensegroups: importedData.expensegroups,
                            expenseaccounts: importedData.expenseaccounts
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })

            setResult('Chart imported successfully.');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Import Chart</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className='modal_textarea'
                                    value={importText}
                                    onChange={e => setImportText(e.target.value)}
                                    placeholder="Paste your import data here..."
                                    rows={10}
                                    cols={50}
                                    style={{ resize: 'vertical' }}
                                />
                            </div>
                        </div>
                        {result && <div className="modal_result">{result}</div>}
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleImport}>Import</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddGroup() {
        const [type, setType] = useState('incomegroup');
        const [name, setName] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccountGroup = (type, name, orderid) => {
            console.log('Trying to add group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info')
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomegroup') {
                const isGroupDuplicate = project.incomegroups.some(group => group.name === trimmedName);
                if (isGroupDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isGroupDuplicate = project.expensegroups.some(group => group.name === trimmedName);
                if (isGroupDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }

            if (type === 'incomegroup') {
                const isOrderDuplicate = project.incomegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isOrderDuplicate = project.expensegroups.some(group =>
                    orderid !== '' &&
                    group.orderid == orderid
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                orderid: parseInt(orderid)
            }

            axios.post(configData.CONTROLLERURL + configData.ADDGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add group data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'ADD_INCOMEGROUP',
                                incomegroup: data
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'ADD_EXPENSEGROUP',
                                expensegroup: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Group</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="incomegroup">Income Group</option>
                                    <option value="expensegroup">Expense Group</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Order ID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    value={orderid}
                                    onChange={(e) => setOrderid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccountGroup(type, name, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditGroup({ group }) {
        const [id, setId] = useState('');

        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [orderid, setOrderid] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(group.id);
            setType(group.type);
            setName(group.name);
            setOrderid(group.orderid);
        }, [group]);

        const updateGroup = (id, type, name, orderid) => {
            console.log('Trying to update group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info')
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomegroup') {
                const isNameDuplicate = project.incomegroups.some(group => group.name === trimmedName && group.id !== id);
                if (isNameDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const isNameDuplicate = project.expensegroups.some(group => group.name === trimmedName && group.id !== id);
                if (isNameDuplicate) {
                    setResult('Group with the same name already exists');
                    return;
                }
            }

            if (type === 'incomegroup') {
                const isOrderDuplicate = project.incomegroups.some(group => 
                    orderid !== '' &&
                    group.orderid == orderid && 
                    group.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Group with the same order already exists');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                console.log(project.expensegroups)
                const isOrderDuplicate = project.expensegroups.some(group => 
                    orderid !== '' &&
                    group.orderid == orderid && 
                    group.id !== id
                );
                if (isOrderDuplicate) {
                    console.log(id)
                    setResult('Group with the same order already exists');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                orderid: parseInt(orderid),
            }

            console.log(data)
            axios.post(configData.CONTROLLERURL + configData.UPDATEGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update group data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'UPDATE_INCOMEGROUP',
                                incomegroup: data
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'UPDATE_EXPENSEGROUP',
                                expensegroup: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Group</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    disabled
                                >
                                    <option value="incomegroup">Income Group</option>
                                    <option value="expensegroup">Expense Group</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Order ID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    value={orderid}
                                    onChange={(e) => setOrderid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateGroup(id, type, name, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButtonGroup(group)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveGroup({ group }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [type, setType] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(group.id)
            setName(group.name)
            setType(group.type)
        }, [group]);

        const removeGroup = (id, type) => {
            console.log('Trying to remove group');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (type === 'incomegroup') {
                const hasAssociatedAccounts = project.incomeaccounts.some(account => account.groupid === id);
                if (hasAssociatedAccounts) {
                    setResult('Cannot delete group because there are accounts associated with it');
                    return;
                }
            }
            else if (type === 'expensegroup') {
                const hasAssociatedAccounts = project.expenseaccounts.some(account => account.groupid === id);
                if (hasAssociatedAccounts) {
                    setResult('Cannot delete group because there are accounts associated with it');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEGROUP, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove group data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomegroup') {
                            dispatch({
                                type: 'REMOVE_INCOMEGROUP',
                                incomegroupid: id
                            });
                        }
                        else if (type === 'expensegroup') {
                            dispatch({
                                type: 'REMOVE_EXPENSEGROUP',
                                expensegroupid: id
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeGroup(id, type)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddAccount() {
        const [type, setType] = useState('incomeaccount');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');
        const [groupid, setGroupid] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccount = (type, name, budget, currencyid, groupid, orderid) => {
            console.log('Trying to add account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isNameDuplicate = project.incomeaccounts.some(account => account.name === trimmedName);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isNameDuplicate = project.expenseaccounts.some(account => account.name === trimmedName);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            if (type === 'incomeaccount') {
                const isOrderDuplicate = project.incomeaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isOrderDuplicate = project.expenseaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid,
                groupid: groupid,
                orderid: orderid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'ADD_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'ADD_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="incomeaccount">Income Account</option>
                                    <option value="expenseaccount">Expense Account</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Budget</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Budget"
                                    value={budget}
                                    onChange={(e) => setBudget(Number(e.target.value) || 0)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={groupid}
                                    onChange={(e) => setGroupid(e.target.value)}
                                >
                                    <option value="">Select Group</option>
                                    {type === 'incomeaccount' && project.incomegroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.name}</option>
                                    ))}
                                    {type === 'expenseaccount' && project.expensegroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Order ID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder=""
                                    value={orderid}
                                    onChange={(e) => setOrderid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccount(type, name, budget, currencyid, groupid, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccount({ account }) {
        const [id, setId] = useState('');

        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [budget, setBudget] = useState(0);
        const [currencyid, setCurrencyid] = useState('');
        const [groupid, setGroupid] = useState('');
        const [orderid, setOrderid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id);
            setType(account.type);
            setName(account.name);
            setBudget(account.budget);
            setCurrencyid(account.currencyid);
            setGroupid(account.groupid);
            setOrderid(account.orderid);
            console.log(account)
        }, [account]);

        const updateAccount = (id, type, name, budget, currencyid, groupid, orderid) => {
            console.log('Trying to update account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid) {
                setResult('Missing info')
                return;
            }

            if (isNaN(parseFloat(budget))) {
                setResult('Invalid budget amount');
                return;
            }

            const trimmedName = name.trim();

            if (type === 'incomeaccount') {
                const isNameDuplicate = project.incomeaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }
            else if (type === 'expenseaccount') {
                const isNameDuplicate = project.expenseaccounts.some(account => account.name === trimmedName && account.id !== id);
                if (isNameDuplicate) {
                    setResult('Account with the same name already exists');
                    return;
                }
            }

            if (type === 'incomeaccount') {
                const isOrderDuplicate = project.incomeaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid &&
                    account.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            } else if (type === 'expenseaccount') {
                const isOrderDuplicate = project.expenseaccounts.some(account =>
                    orderid !== '' &&
                    account.orderid == orderid &&
                    account.groupid === groupid &&
                    account.id !== id
                );
                if (isOrderDuplicate) {
                    setResult('Account with the same order already exists within the same group');
                    return;
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                budget: budget,
                currencyid: currencyid,
                groupid: groupid,
                orderid: orderid,
            }

            console.log(data)
            axios.post(configData.CONTROLLERURL + configData.UPDATEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'UPDATE_INCOMEACCOUNT',
                                incomeaccount: data
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'UPDATE_EXPENSEACCOUNT',
                                expenseaccount: data
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Type</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    disabled
                                >
                                    <option value="incomeaccount">Income Account</option>
                                    <option value="expenseaccount">Expense Account</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Acount Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Budget</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder={0}
                                    value={budget}
                                    onChange={(e) => setBudget(Number(e.target.value) || 0)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Group Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={groupid}
                                    onChange={(e) => setGroupid(e.target.value)}
                                >
                                    <option value="">Select Group</option>
                                    {type === 'incomeaccount' && project.incomegroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.name}</option>
                                    ))}
                                    {type === 'expenseaccount' && project.expensegroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Order ID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder=""
                                    value={orderid}
                                    onChange={(e) => setOrderid(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAccount(id, type, name, budget, currencyid, groupid, orderid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButtonAccount(account)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [type, setType] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
            setType(account.type)
        }, [account]);

        const removeAccount = (id, type) => {
            console.log('Trying to remove account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Chart of Accounts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const journalentryData = journalentryremovalprotection(project.journalentries, id, 'chartofaccounts');
            if (journalentryData.code) {
                setResult(journalentryData.message);
                return
            }

            if (type === 'expenseaccount') {
                const transactionData = transactionsremovalprotection(project.transactions, id, 'chartofaccounts');
                if (transactionData.code) {
                    setResult(transactionData.message);
                    return
                }
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        if (type === 'incomeaccount') {
                            dispatch({
                                type: 'REMOVE_INCOMEACCOUNT',
                                incomeaccountid: id
                            });
                        }
                        else if (type === 'expenseaccount') {
                            dispatch({
                                type: 'REMOVE_EXPENSEACCOUNT',
                                expenseaccountid: id
                            });
                        }
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAccount(id, type)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='chartaccounts'>
            <Title text='Chart of Accounts' />
            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButtonGroup}>Add Group</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={addButtonAccount}>Add Account</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Order ID</th><th>Name</th><th>Budget</th></tr></thead>
                <tbody>
                    {
                        chartaccountsdata.map((item, index) => {
                            if (item.type === 'title') {
                                return (
                                    <tr key={'title' + index}>
                                        <td colSpan={4}><div className='chartaccounts_accounttitle'>{item.name}</div></td>
                                    </tr>
                                );
                            }
                            if (item.type === 'incomegroup' || item.type === 'expensegroup') {
                                return (
                                    <tr key={'group' + index}>
                                        <td><div className='table_button' onClick={() => editButtonGroup(item)}>Edit</div></td>
                                        <td><div className='chartaccounts_group'>{item.orderid}</div></td>
                                        <td><div className='chartaccounts_group'>{item.name}</div></td>
                                        <td></td>
                                    </tr>
                                )
                            }
                            if (item.type === 'incomeaccount' || item.type === 'expenseaccount') {
                                return (
                                    <tr key={'account' + index}>
                                        <td><div className='table_button' onClick={() => editButtonAccount(item)}>Edit</div></td>
                                        <td><div>{item.orderid}</div></td>
                                        <td><div>{item.name}</div></td>
                                        <td><div>{item.currencysymbol} {item.budget.toLocaleString('en-US')}</div></td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default ChartAccounts;