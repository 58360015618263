import React, { useRef, useEffect, useState } from 'react';
import { useStateValue } from './StateProvider';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import html3pdf from 'html3pdf';

import { getCashAccountSOA, getClientAccountSOA, getSupplierAccountSOA, getIncomeAccountSOA, getExpenseAccountSOA } from './Utils/statementaccount.js';

import './StatementAccount.css';

function StatementAccount({ type, account, setOption }) {
    const [{ project }, dispatch] = useStateValue();

    const printRef = useRef();

    const cancelButton = () => {
        setOption(null);
    };

    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    function IncomeAccountSOA() {
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const incomeaccountsoa = getIncomeAccountSOA(account, project.journalentries, project.exchangerates, project.currencies, project.basecurrency, account.from, account.to);
            setStatementData(incomeaccountsoa);
        }, [account, project.journalentries, project.exchangerates, project.currencies, project.basecurrency]);

        const saveCashSoaPDF = async (name) => {
            const content = printRef.current;

            const clientName = name.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'soa_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Statement of Account</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>
                                        {project.name}
                                    </div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>{account.name}</div>
                                </div>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead><tr><th>Date</th><th>Type</th><th>Name</th><th>Debit</th><th>Credit</th><th>Balance</th></tr></thead>
                                    <tbody>
                                        {
                                            statementData.map((entry, index) => {
                                                return (
                                                    <tr key={'entry' + index}>
                                                        <td><div>{entry.date}</div></td>
                                                        <td><div>{entry.type}</div></td>
                                                        <td><div>{entry.name}</div></td>
                                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal_buttonscontainer'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                                pageStyle={`
                                    @page {
                                        size: A4;
                                    }
                                    @media print {
                                        tfoot {
                                            display: table-row-group;
                                        }
                                        @top-center, @bottom-center {
                                            content: none;
                                        }
                                    }
                                `}
                            />
                            <button className='modal_button' onClick={() => saveCashSoaPDF(account.name)}>Save as PDF</button>
                            <button className='modal_button' onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ExpenseAccountSOA() {
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const expenseaccountsoa = getExpenseAccountSOA(account, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency, account.from, account.to);
            setStatementData(expenseaccountsoa);
        }, [account, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency]);

        const saveCashSoaPDF = async (name) => {
            const content = printRef.current;

            const clientName = name.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'soa_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Statement of Account</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>
                                        {project.name}
                                    </div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>{account.name}</div>
                                </div>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            statementData.map((entry, index) => {
                                                return (
                                                    <tr key={'entry' + index}>
                                                        <td><div>{entry.date}</div></td>
                                                        <td><div>{entry.type}</div></td>
                                                        <td><div>{entry.name}</div></td>
                                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal_buttonscontainer'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                                pageStyle={`
                                    @page {
                                        size: A4;
                                    }
                                    @media print {
                                        tfoot {
                                            display: table-row-group;
                                        }
                                        @top-center, @bottom-center {
                                            content: none;
                                        }
                                    }
                                `}
                            />
                            <button className='modal_button' onClick={() => saveCashSoaPDF(account.name)}>Save as PDF</button>
                            <button className='modal_button' onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function CashAccountSOA() {
        const [statementData, setStatementData] = useState([]);
        const [reconciliation, setReconciliation] = useState(false);

        useEffect(() => {
            const cashaccountsoa = getCashAccountSOA(account, project.transactions, project.interaccounttransfers, project.reconciliations, project.currencies, project.startdate, project.exchangerates);
            setStatementData(cashaccountsoa);
        }, [account, project.transactions, project.interaccounttransfers, project.currencies, project.startdate]);

        const saveCashSoaPDF = async (name) => {
            const content = printRef.current;

            const clientName = name.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'soa_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        const currency = project.currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';
        const totalDebit = statementData.reduce((acc, entry) => acc + entry.debitamount, 0);
        const totalCredit = statementData.reduce((acc, entry) => acc + entry.creditamount, 0);
        const totalBalance = totalDebit - totalCredit;

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Statement of Account</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>
                                        {project.name}
                                    </div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>{account.name}</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <label className='modal_label' htmlFor="reconciliation">
                                        Reconciliation
                                        <input
                                            type="checkbox"
                                            id="reconciliation"
                                            checked={reconciliation}
                                            onChange={(e) => setReconciliation(e.target.checked)}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                            {reconciliation && <th>Reconciliation</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            statementData.map((entry, index) => {
                                                return (
                                                    <tr key={'entry' + index}>
                                                        <td><div>{entry.date}</div></td>
                                                        <td><div>{entry.type}</div></td>
                                                        <td><div>{entry.name}</div></td>
                                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                        {reconciliation && <td><div>{entry.reconciliationstatus}</div></td>}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={3}><strong>Total</strong></td>
                                            <td><strong>{currency + " " + totalDebit.toLocaleString('en-US')}</strong></td>
                                            <td><strong>{currency + " " + totalCredit.toLocaleString('en-US')}</strong></td>
                                            <td><strong>{currency + " " + totalBalance.toLocaleString('en-US')}</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='modal_buttonscontainer'>
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                            pageStyle={`
                                @page {
                                    size: A4;
                                }
                                @media print {
                                    tfoot {
                                        display: table-row-group;
                                    }
                                    @top-center, @bottom-center {
                                        content: none;
                                    }
                                }
                            `}
                        />
                        <button className='modal_button' onClick={() => saveCashSoaPDF(account.name)}>Save as PDF</button>
                        <button className='modal_button' onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function ClientAccountSOA() {
        const [selectedcurrency, setSelectedcurrency] = useState(project.basecurrency);
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [statementdata, setStatementdata] = useState([]);
        const [statementsummary, setStatementsummary] = useState({ currency: '', totaldebit: '', totalcredit: '', totalbalance: '' });

        useEffect(() => {
            const clientaccountsoa = getClientAccountSOA(account, selectedsubaccount, project.transactions, project.journalentries, project.exchangerates, project.currencies, selectedcurrency);
            setStatementdata(clientaccountsoa.data);
            setStatementsummary(clientaccountsoa.summary)
        }, [account, selectedcurrency, selectedsubaccount, project.transactions, project.journalentries, project.exchangerates, project.currencies]);

        const saveClientSoaPDF = async (name) => {
            const content = printRef.current;

            const clientName = name.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: [10, 10],
                filename: 'soa_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Statement of Account</div>
                            <div className='modal_row'>
                                <div className='modal_label'>
                                    {project.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_label'>{account.name}</div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_label'>
                                    Subaccount
                                </div>
                            </div>
                            <div className='modal_row'>
                                <select className='modal_select' value={selectedsubaccount} onChange={(e) => setSelectedSubaccount(e.target.value)}>
                                    <option value={0}>All</option>
                                    {
                                        project.subaccounts.map((subaccount) => (
                                            <option key={subaccount.id} value={subaccount.id}>
                                                {subaccount.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_label'>
                                    Currency
                                </div>
                            </div>
                            <div className='modal_row'>
                                <select className='modal_select' value={selectedcurrency} onChange={(e) => setSelectedcurrency(e.target.value)}>
                                    {
                                        project.currencies.map((currency) => (
                                            <option key={currency.id} value={currency.id}>
                                                {currency.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statementdata.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index} style={{ pageBreakInside: 'avoid' }}>
                                                    <td><div>{entry.date}</div></td>
                                                    <td><div>{entry.type}</div></td>
                                                    <td><div>{entry.id}</div></td>
                                                    <td><div>{entry.name}</div></td>
                                                    <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                                    <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                                    <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot className='soa_tablefooter'>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Debit</td><td>{statementsummary.currency} {statementsummary.totaldebit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Total Credit</td><td>{statementsummary.currency} {statementsummary.totalcredit.toLocaleString('en-US')}</td></tr>
                                    <tr><td colSpan={4}></td><td colSpan={2}>Closing Balance</td><td>{statementsummary.currency} {statementsummary.totalbalance.toLocaleString('en-US')}</td></tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='modal_buttonscontainer'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                                documentTitle=''
                                pageStyle={`
                                    @page {
                                        size: A4;
                                    }
                                    @media print {
                                        tfoot {
                                            display: table-row-group;
                                        }
                                    }
                                `}
                            />
                            <button className='modal_button' onClick={() => saveClientSoaPDF(account.name)}>Save as PDF</button>
                            <button className='modal_button' onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function SupplierAccountSOA() {
        const [selectedSubaccountType, setSelectedSubaccountType] = useState(0);
        const [statementData, setStatementData] = useState([]);

        useEffect(() => {
            const supplieraccountsoa = getSupplierAccountSOA(account, selectedSubaccountType, project.transactions, project.journalentries, project.purchaseinvoices, project.exchangerates, project.currencies, project.basecurrency);
            setStatementData(supplieraccountsoa);
        }, [account, selectedSubaccountType, project.transactions, project.journalentries, project.purchaseinvoices, project.exchangerates, project.currencies]);

        const saveSupplierSoaPDF = async (name, subaccountid) => {
            let subName = project.subaccounts.find((sub) => sub.id === subaccountid)?.name || '';
            const content = printRef.current;

            const clientName = name.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'soa_' + subName + '_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        const currency = project.currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';
        const totalDebit = statementData.reduce((acc, entry) => acc + entry.debitamount, 0);
        const totalCredit = statementData.reduce((acc, entry) => acc + entry.creditamount, 0);
        const totalBalance = totalDebit - totalCredit;

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Statement of Account</div>
                            <div className='modal_row'>
                                <div className='modal_label'>
                                    {project.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_label'>{account.name}</div>
                            </div>
                            <div className='modal_row'>
                                <label>
                                    <select className='modal_select' value={selectedSubaccountType} onChange={(e) => setSelectedSubaccountType(e.target.value)}>
                                        <option value={0}>All</option>
                                        {
                                            project.subaccounts.map((subaccount) => (
                                                <option key={subaccount.id} value={subaccount.id}>
                                                    {subaccount.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </label>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead><tr><th>Date</th><th>Name</th><th>Debit</th><th>Credit</th><th>Balance</th></tr></thead>
                                    <tbody>
                                        {
                                            statementData.map((entry, index) => {
                                                return (
                                                    <tr key={'entry' + index}>
                                                        <td><div>{entry.date}</div></td>
                                                        <td><div>{entry.name}</div></td>
                                                        <td><div>{entry.debitcurrency} {entry.debitamount.toLocaleString('en-US')}</div></td>
                                                        <td><div>{entry.creditcurrency} {entry.creditamount.toLocaleString('en-US')}</div></td>
                                                        <td><div className='statementaccount_balance'>{entry.balancecurrency} {entry.balanceamount.toLocaleString('en-US')}</div></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}><strong>Total</strong></td>
                                            <td><strong>{currency + totalDebit.toLocaleString('en-US')}</strong></td>
                                            <td><strong>{currency + totalCredit.toLocaleString('en-US')}</strong></td>
                                            <td><strong>{currency + totalBalance.toLocaleString('en-US')}</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='modal_buttonscontainer'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                                pageStyle={`
                                    @page {
                                        size: A4;
                                    }
                                    @media print {
                                        tfoot {
                                            display: table-row-group;
                                        }
                                        @top-center, @bottom-center {
                                            content: none;
                                        }
                                    }
                                `}
                            />
                            <button className='modal_button' onClick={() => saveSupplierSoaPDF(account.name)}>Save as PDF</button>
                            <button className='modal_button' onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {type === 'incomeaccounts' && <IncomeAccountSOA />}
            {type === 'expenseaccounts' && <ExpenseAccountSOA />}
            {type === 'cashaccounts' && <CashAccountSOA />}
            {type === 'clients' && <ClientAccountSOA />}
            {type === 'suppliers' && <SupplierAccountSOA />}
        </>
    );
}

export default StatementAccount;