import { findNearestExchangeRate } from './currency.js';
import moment from 'moment';

export function getCashAccountSOA(account, transactions, interaccounttransfers, reconciliations, currencies, startdate, exchangerates) { //20240524
    const dataArray = [];
    const currency = currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';

    let openingBalance = parseFloat(account.openingbalance);

    if (openingBalance !== 0) {
        const date = moment.unix(startdate).format('D/MMM/YYYY');
        const isDebit = openingBalance < 0;

        dataArray.push({
            ts: startdate,
            date: date,
            type: 'OPEN',
            name: 'Opening Balance',
            debitcurrency: currency,
            debitamount: isDebit ? 0 : openingBalance,
            creditcurrency: currency,
            creditamount: isDebit ? Math.abs(openingBalance) : 0
        });
    }

    transactions.forEach((transaction) => {
        const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
        let debit = 0;
        let credit = 0;

        if (transaction.from === account.id) {
            if (transaction.currencyid === account.currencyid) {
                credit = parseFloat(transaction.amount);
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                const exchangerate = NearestExchangeRate.exchangerate;
                credit = exchangerate * parseFloat(transaction.amount);
            }
            dataArray.push({
                ts: transaction.ts,
                date: date,
                type: 'RCPT',
                name: transaction.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });
        }
        if (transaction.to === account.id) {
            if (transaction.currencyid === account.currencyid) {
                debit = parseFloat(transaction.amount);
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                const exchangerate = NearestExchangeRate.exchangerate;
                debit = exchangerate * parseFloat(transaction.amount);
            }
            dataArray.push({
                ts: transaction.ts,
                date: date,
                type: 'PYMT',
                name: transaction.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });
        }
    });

    interaccounttransfers.forEach((transfer) => {
        const date = moment.unix(transfer.ts).format('D/MMM/YYYY');
        let debit = 0;
        let credit = 0;

        if (transfer.to === account.id) {
            if (transfer.tocurrencyid === account.currencyid) {
                debit = parseFloat(transfer.toamount);
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.tocurrencyid, account.currencyid);
                const exchangerate = NearestExchangeRate.exchangerate;
                debit = parseFloat(transfer.toamount) * exchangerate;
            }
        }

        if (transfer.from === account.id) {
            if (transfer.fromcurrencyid === account.currencyid) {
                credit = parseFloat(transfer.fromamount);
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transfer.ts, transfer.fromcurrencyid, account.currencyid);
                const exchangerate = NearestExchangeRate.exchangerate;
                credit = parseFloat(transfer.fromamount) * exchangerate;
            }
        }


        if (debit !== 0 || credit !== 0) {
            dataArray.push({
                ts: transfer.ts,
                date: date,
                type: 'TRNSFR',
                name: transfer.name,
                debitcurrency: currency,
                debitamount: debit,
                creditcurrency: currency,
                creditamount: credit
            });
        }
    });

    dataArray.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    dataArray.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = balance - entry.creditamount + entry.debitamount;
        balance = entry.balanceamount;
    });

    const relevantReconciliations = reconciliations.filter(reconciliation => reconciliation.cashaccountid === account.id);
    relevantReconciliations.sort((a, b) => a.ts - b.ts);

    let reconciled = true;
    dataArray.forEach((entry) => {
        let matchingReconciliation = relevantReconciliations.find((reconciliation) => {
            return moment.unix(entry.ts).startOf('day').isSame(moment.unix(reconciliation.ts).startOf('day'));
        });

        if (matchingReconciliation) {
            if (parseFloat(entry.balanceamount) !== parseFloat(matchingReconciliation.balance)) {
                let foundMatchingBalance = dataArray.some((otherEntry) => {
                    return moment.unix(otherEntry.ts).startOf('day').isSame(moment.unix(entry.ts).startOf('day')) &&
                        parseFloat(otherEntry.balanceamount) === parseFloat(matchingReconciliation.balance);
                });

                if (!foundMatchingBalance) {
                    reconciled = false;
                }
            }
        }

        if (reconciled) {
            entry.reconciliationstatus = "Reconciled";
            if (matchingReconciliation) entry.reconciliationstatus = entry.reconciliationstatus + ' ' + matchingReconciliation.balance + entry.creditcurrency;
        }
        if (!reconciled) {
            entry.reconciliationstatus = "Not Reconciled";
            if (matchingReconciliation) entry.reconciliationstatus = entry.reconciliationstatus + ' ' + matchingReconciliation.balance + entry.creditcurrency;
        }
    });

    return dataArray;
}

export function getClientAccountSOA(account, subaccount, transactions, journalentries, exchangerates, currencies, basecurrency) {
    const statementData = { data: [], summary: {} };
    const data = [];
    const summary = { totalcredit: 0, totaldebit: 0, totalbalance: 0 };

    const balancecurrencyid = basecurrency;
    const balancecurrencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

    transactions.forEach((transaction) => {
        if (transaction.from === account.id) {
            if (subaccount == 0 || transaction.subaccount === subaccount) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                const debit = 0;
                const credit = parseFloat(transaction.amount);

                data.push({
                    ts: transaction.ts,
                    type: 'RCPT',
                    id: transaction.id,
                    date: date,
                    name: transaction.name,
                    debitcurrencyid: transaction.currencyid,
                    debitamount: debit,
                    creditcurrencyid: transaction.currencyid,
                    creditamount: credit
                });

            }
        }
    });

    journalentries.forEach((entry) => {
        if (subaccount == 0 || entry.subaccount === subaccount) {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    const debit = parseFloat(row.debit);
                    const credit = parseFloat(row.credit);

                    data.push({
                        ts: entry.ts,
                        type: 'JV',
                        id: entry.id,
                        date: date,
                        name: row.name ? row.name : entry.name,
                        debitcurrencyid: entry.currencyid,
                        debitamount: debit,
                        creditcurrencyid: entry.currencyid,
                        creditamount: credit
                    });

                }
            });
        }
    });

    data.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        if (entry.debitcurrencyid !== balancecurrencyid) {
            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.debitcurrencyid, basecurrency);
            const exchangerate = NearestExchangeRate.exchangerate;
            entry.balanceamount = balance + parseFloat(entry.debitamount * exchangerate) - parseFloat(entry.creditamount * exchangerate);
            summary.totaldebit += parseFloat(entry.debitamount * exchangerate);
            summary.totalcredit += parseFloat(entry.creditamount * exchangerate);
        }
        else {
            entry.balanceamount = balance + entry.debitamount - entry.creditamount;
            summary.totaldebit += parseFloat(entry.debitamount);
            summary.totalcredit += parseFloat(entry.creditamount);
        }

        entry.debitcurrency = currencies.find((currency) => currency.id === entry.debitcurrencyid)?.symbol;
        entry.creditcurrency = currencies.find((currency) => currency.id === entry.creditcurrencyid)?.symbol;
        entry.balancecurrency = balancecurrencysymbol;
        balance = entry.balanceamount;
    });

    summary.totalbalance = summary.totaldebit - summary.totalcredit;
    summary.currency = balancecurrencysymbol;
    statementData.data = data;
    statementData.summary = summary;

    return statementData;
}

export function getSupplierAccountSOA(account, subaccount, transactions, journalentries, purchaseinvoices, exchangerates, currencies, basecurrency) {
    const dataArray = [];
    const currency = currencies.find((currency) => currency.id === account.currencyid)?.symbol || '';

    transactions.forEach((transaction) => {
        if (transaction.beneficiaryid === account.id) {
            if (subaccount == 0 || transaction.subaccount === subaccount) {
                const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
                let debit = 0;
                let credit = 0;

                if (transaction.currencyid === account.currencyid) {
                    credit = parseFloat(transaction.amount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    credit = parseFloat(transaction.amount) * exchangerate;
                }

                dataArray.push({
                    ts: transaction.ts,
                    date: date,
                    name: transaction.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
        }
    });

    journalentries.forEach((entry) => {
        entry.rows.forEach((row) => {
            if (row.accountid === account.id) {
                if (subaccount == 0 || entry.subaccount === subaccount) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    let debit = 0;
                    let credit = 0;
                    if (entry.currencyid === account.currencyid) {
                        debit = parseFloat(row.debit);
                        credit = parseFloat(row.credit);
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, row.currencyid, basecurrency)
                        const exchangerate = NearestExchangeRate.exchangerate;
                        debit = parseFloat(row.debit) * exchangerate;
                        credit = parseFloat(row.credit) * exchangerate;
                    }

                    dataArray.push({
                        ts: entry.ts,
                        date: date,
                        name: row.name ? row.name : entry.name,
                        debitcurrency: currency,
                        debitamount: debit,
                        creditcurrency: currency,
                        creditamount: credit
                    });
                }
            }
        });
    });

    purchaseinvoices.forEach((invoice) => {
        if (invoice.supplierid === account.id) {
            if (subaccount == 0 || invoice.subaccount === subaccount) {
                const date = moment.unix(invoice.ts).format('D/MMM/YYYY');
                let debit = 0;
                let credit = 0;

                if (invoice.currencyid === account.currencyid) {
                    debit = parseFloat(invoice.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency)
                    const exchangerate = NearestExchangeRate.exchangerate;
                    debit = parseFloat(invoice.amount) * exchangerate
                }

                dataArray.push({
                    ts: invoice.ts,
                    date: date,
                    name: invoice.name,
                    debitcurrency: currency,
                    debitamount: debit,
                    creditcurrency: currency,
                    creditamount: credit
                });
            }
        }
    });

    dataArray.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    dataArray.forEach((entry) => {
        entry.balancecurrency = entry.creditcurrency;
        entry.balanceamount = balance + entry.debitamount - entry.creditamount;
        balance = entry.balanceamount;
    });

    return dataArray;
}

export function getIncomeAccountSOA(account, journalentries, exchangerates, currencies, basecurrency, from, to) {
    const data = [];

    const currencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to) {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    let debit = 0;
                    let credit = 0;

                    if (entry.currencyid === account.currencyid) {
                        debit = parseFloat(row.debit);
                        credit = parseFloat(row.credit);
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        debit = parseFloat(row.debit) * exchangerate;
                        credit = parseFloat(row.credit) * exchangerate;
                    }

                    data.push({
                        ts: entry.ts,
                        id: entry.id,
                        date: date,
                        type: 'JV',
                        name: row.name ? row.name : entry.name,
                        debitcurrency: currencysymbol,
                        debitamount: debit,
                        creditcurrency: currencysymbol,
                        creditamount: credit
                    });

                }
            });
        }
    });

    data.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = currencysymbol;
        entry.balanceamount = balance + entry.creditamount - entry.debitamount;
        balance = entry.balanceamount;
    });

    return data;
}

export function getExpenseAccountSOA(account, transactions, journalentries, exchangerates, currencies, basecurrency, from, to) {
    const data = [];

    const currencysymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

    transactions.forEach((transaction) => {
        if (transaction.to === account.id && transaction.ts >= from && transaction.ts <= to) {
            const date = moment.unix(transaction.ts).format('D/MMM/YYYY');
            let debit = 0;
            let credit = 0;

            if (transaction.currencyid === account.currencyid) {
                credit = parseFloat(transaction.amount);
            }
            else {
                const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                const exchangerate = NearestExchangeRate.exchangerate;
                credit = parseFloat(transaction.amount) * exchangerate;
            }

            data.push({
                ts: transaction.ts,
                id: transaction.id,
                date: date,
                type: 'PYMT',
                name: transaction.name,
                debitcurrency: currencysymbol,
                debitamount: debit,
                creditcurrency: currencysymbol,
                creditamount: credit
            });
        }
    });

    journalentries.forEach((entry) => {
        if (entry.ts >= from && entry.ts <= to) {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    const date = moment.unix(entry.ts).format('D/MMM/YYYY');
                    const debit = parseFloat(row.debit);
                    const credit = parseFloat(row.credit);

                    data.push({
                        ts: entry.ts,
                        id: entry.id,
                        date: date,
                        date: 'JV',
                        name: row.name ? row.name : entry.name,
                        debitcurrency: currencysymbol,
                        debitamount: debit,
                        creditcurrency: currencysymbol,
                        creditamount: credit
                    });

                }
            });
        }
    });

    data.sort((a, b) => {
        if (a.ts === b.ts) {
            return a.ts - (b.ts + 1);
        }
        return a.ts - b.ts;
    });

    let balance = 0;
    data.forEach((entry) => {
        entry.balancecurrency = currencysymbol;
        entry.balanceamount = balance + entry.creditamount - entry.debitamount;
        balance = entry.balanceamount;
    });

    return data;
}