import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';
import StatementAccount from './StatementAccount';
import { getClientAccounts } from './Utils/clients.js';
import { journalentryremovalprotection, transactionsremovalprotection, emsreadingsremovalprotection, emsmetersremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';
import './Clients.css';

function Clients({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredclients, setFilteredclients] = useState([]);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState('');

    const [option, setOption] = useState(null);

    const clientsdata = getClientAccounts(project.clients, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = clientsdata.filter((client) =>
            client.name.toLowerCase().includes(search.toLowerCase())
        );

        if (order === 'asc') {
            filtered = filtered.sort((a, b) => a.balance - b.balance);
        }
        else if (order === 'desc') {
            filtered = filtered.sort((a, b) => b.balance - a.balance);
        }

        setFilteredclients(filtered);

    }, [project, search, order]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddClient />);
    }

    const editButton = (client) => {
        setOption(<EditClient client={client} />);
    }

    const viewButton = (client) => {
        setOption(<ViewClient client={client} />);
    }

    const viewClientsStatus = (clients) => {
        setOption(<ViewClientsStatus clients={clients} />);
    }

    const removeButton = (client) => {
        setOption(<RemoveClient client={client} />);
    }

    const handleAccountStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />);
    }

    const copyButton = () => {
        if (!clientsdata || clientsdata.length === 0) {
            console.log('No data to copy.');
            return;
        }

        let tsvContent = "Name\tBalance\n";
        tsvContent += clientsdata.map(client => `${client.name}\t${client.currency} ${client.balance.toFixed(2)}`).join('\n');

        navigator.clipboard.writeText(tsvContent)
            .then(() => console.log('Data copied to clipboard'))
            .catch(err => console.error('Error copying to clipboard:', err));
    };

    const exportPage = () => {
        setOption(<ExportPage />)
    }

    function ExportPage() {
        useEffect(() => {
            if (!project || !project.clients || project.clients.length === 0) {
                console.log('No users data to export.');
                return;
            }

            const usersContent = JSON.stringify(project.clients, null, 2);

            navigator.clipboard.writeText(usersContent)
                .then(() => console.log('Users data copied to clipboard'))
                .catch(err => console.error('Error copying users data to clipboard:', err));
        }, []);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Clients Exported</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const importPage = () => {
        setOption(<ImportPage />)
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [result, setResult] = useState('');

        const handleImport = () => {
            const importedClients = JSON.parse(importText);
            if (!(importedClients instanceof Array)) {
                setResult('Imported data is not in the expected format.');
                return;
            }

            const duplicate = importedClients.find(importClient =>
                project.clients.some(projectClient =>
                    projectClient.id === importClient.id || projectClient.name === importClient.name
                )
            );

            if (duplicate) {
                setResult(`Client already exists with ID: ${duplicate.id} or Name: ${duplicate.name}`);
                return;
            }

            console.log('Trying to import clients');
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                clients: importedClients
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCLIENTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import clients data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CLIENTS',
                            clients: importedClients
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })

            setResult('Clients imported successfully.');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Import Clients</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className='modal_textarea'
                                    value={importText}
                                    onChange={e => setImportText(e.target.value)}
                                    placeholder="Paste your import data here..."
                                    rows={10}
                                    cols={50}
                                    style={{ resize: 'vertical' }}
                                />
                            </div>
                        </div>
                        {result && <div className="modal_result">{result}</div>}
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleImport}>Import</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewNumber({ row, index, handleNameChangeNumber, handleNumberChangeNumber, handleRemoveNumber }) {
        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Name'
                        value={row.name}
                        onChange={(e) => handleNameChangeNumber(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Number'
                        value={row.number}
                        onChange={(e) => handleNumberChangeNumber(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveNumber(index)}>X</div>
                </td>
            </tr>
        );
    }

    function NewDevice({ row, index, handleEmailChangeDevice, handleRemoveDevice }) {
        return (
            <tr>
                <td>
                    {row.id}
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Email'
                        value={row.email}
                        onChange={(e) => handleEmailChangeDevice(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveDevice(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddClient() {
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [numbers, setNumbers] = useState([]);
        const [devices, setDevices] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const handleAddNumber = () => {
            setNumbers([...numbers, { name: '', number: '' }]);
        };

        const handleNameChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].name = e.target.value;
            setNumbers(newNumbers);
        };

        const handleNumberChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].number = e.target.value;
            setNumbers(newNumbers);
        };

        const handleRemoveNumber = (index) => {
            const newNumbers = [...numbers];
            newNumbers.splice(index, 1);
            setNumbers(newNumbers);
        };

        const handleAddDevice = () => {
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const addClient = (name, email, numbers, devices) => {
            console.log('Trying to add client');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Clients', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.clients.some(client => client.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Client with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName,
                email: email,
                numbers: numbers,
                devices: devices
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CLIENT',
                            client: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Client</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Numbers</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        numbers.length ?
                                            numbers.map((row, index) => (
                                                <NewNumber
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleNameChangeNumber={handleNameChangeNumber}
                                                    handleNumberChangeNumber={handleNumberChangeNumber}
                                                    handleRemoveNumber={handleRemoveNumber}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={3}>No numbers</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal_row">
                            <button className="modal_button" onClick={handleAddNumber}>Add Number</button>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Devices</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length ?
                                            devices.map((row, index) => (
                                                <NewDevice
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleEmailChangeDevice={handleEmailChangeDevice}
                                                    handleRemoveDevice={() => handleRemoveDevice(index)}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={3}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal_row">
                            <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addClient(name, email, numbers, devices)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function EditClient({ client }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [numbers, setNumbers] = useState([]);
        const [devices, setDevices] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (client) {
                setId(client.id);
                setName(client.name);
                setEmail(client.email);

                const numbersCopy = client.numbers ? client.numbers.map(number => ({ ...number })) : [];
                const devicesCopy = client.devices ? client.devices.map(device => ({ ...device })) : [];

                setNumbers(numbersCopy);
                setDevices(devicesCopy);
            }
        }, [client]);

        const handleAddNumber = () => {
            setNumbers([...numbers, { name: '', number: '' }]);
        };

        const handleNameChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].name = e.target.value;
            setNumbers(newNumbers);
        };

        const handleNumberChangeNumber = (e, index) => {
            const newNumbers = [...numbers];
            newNumbers[index].number = e.target.value;
            setNumbers(newNumbers);
        };

        const handleRemoveNumber = (index) => {
            const newNumbers = [...numbers];
            newNumbers.splice(index, 1);
            setNumbers(newNumbers);
        };

        const handleAddDevice = () => {
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const updateClient = (id, name, email, numbers, devices) => {
            console.log('Trying to update client');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Clients', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info');
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.clients.some(client => client.name === trimmedName && client.id !== id);
            if (isNameDuplicate) {
                setResult('Client with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName,
                email: email,
                numbers: numbers,
                devices: devices
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CLIENT',
                            client: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Client</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Numbers</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Number</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    numbers.length ?
                                        numbers.map((row, index) => (
                                            <NewNumber
                                                key={index}
                                                row={row}
                                                index={index}
                                                handleNameChangeNumber={handleNameChangeNumber}
                                                handleNumberChangeNumber={handleNumberChangeNumber}
                                                handleRemoveNumber={handleRemoveNumber}
                                            />
                                        ))
                                        :
                                        <tr><td colSpan={3}>No numbers</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleAddNumber}>Add Number</button>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Devices</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length ?
                                            devices.map((row, index) => (
                                                <NewDevice
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleEmailChangeDevice={handleEmailChangeDevice}
                                                    handleRemoveDevice={() => handleRemoveDevice(index)}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={3}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateClient(id, name, email, numbers, devices)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(client)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewClient({ client }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [numbers, setNumbers] = useState([]);
        const [devices, setDevices] = useState([]);

        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            setId(client.id)
            setName(client.name)
            setEmail(client.email)
            setNumbers(client.numbers)
            setDevices(client.devices)
        }, [client]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Client</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {id}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Email</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {email}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Numbers</div>
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        numbers.length > 0 ?
                                            numbers.map((number, index) => {
                                                return (
                                                    <tr key={'number' + index}>
                                                        <td>{number.name}</td>
                                                        <td>{number.number}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr><td colSpan={2}>No number</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Devices</div>
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length > 0 ?
                                            devices.map((device, index) => {
                                                return (
                                                    <tr key={'device' + index}>
                                                        <td>{device.id}</td>
                                                        <td>{device.email}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr><td colSpan={2}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                                pageStyle={`
                                    @page {
                                        size: A4;
                                        margin: 0;
                                    }
                                `}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewClientsStatus({ clients }) {
        const [statusData, setStatusData] = useState([]);
        const [loading, setLoading] = useState(true);
        const [result, setResult] = useState('');
        const [refresh, setRefresh] = useState(false);

        const allemails = clients.flatMap(client => [{ id: client.id, type: 'client', email: client.email }, ...client.devices.map(device => ({ id: device.id, type: 'device', email: device.email }))]).filter(({ email }) => email);

        useEffect(() => {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                emails: allemails.map(({ email }) => email)
            }

            axios.post(configData.CONTROLLERURL + configData.GETUSERSSTATUS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Users status data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setStatusData(res.data);
                }
            })
        }, [refresh]);

        const connectClient = (email) => {
            console.log('Trying to connect client');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Clients', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!email) {
                setResult('Missing info');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email,
            }

            axios.post(configData.CONTROLLERURL + configData.CONNECTCLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Connect client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult('Success')
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const disconnectClient = (email) => {
            console.log('Trying to disconnect client');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Clients', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!email) {
                setResult('Missing info');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email,
            }

            axios.post(configData.CONTROLLERURL + configData.DISCONNECTCLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Disconnect client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult('Success')
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const getStatus = (email) => {
            const status = statusData.find(item => item.email === email) || {};
            const { registered, connected, lastlogin } = status;

            if (!registered) return 'Not registered';
            if (!connected) return 'Not connected'
            if (!lastlogin) return 'No last login';
            if (lastlogin) return moment.unix(lastlogin).format('dddd, Do MMMM YYYY, h:mm:ss A');
        };

        const renderActionButton = (email) => {
            const status = statusData.find(item => item.email === email) || {};

            if (status.registered & !status.connected) {
                return (
                    <button className="add-client-button" onClick={() => connectClient(email)}>
                        Connect client
                    </button>
                );
            }

            if (status.connected) {
                return (
                    <button className="add-client-button" onClick={() => disconnectClient(email)}>
                        Disconnect client
                    </button>
                );
            }
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>View Clients Status</div>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Userid</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allemails.map(({ id, userid, type, email }, index) => (
                                        <tr key={'email' + index}>
                                            <td>{id}</td>
                                            <td>{userid}</td>
                                            <td>{type}</td>
                                            <td>{email}</td>
                                            <td>{getStatus(email)}</td>
                                            <td>{renderActionButton(email)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveClient({ client }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(client.id)
            setName(client.name)
        }, [client]);

        const removeClient = (clientid) => {
            console.log('Trying to remove client');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Clients', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const journalEntryData = journalentryremovalprotection(project.journalentries, id, 'clients');
            if (journalEntryData.code) {
                setResult(journalEntryData.message);
                return
            }

            const emsreadingData = emsreadingsremovalprotection(project.readings, id, 'clients');
            if (emsreadingData.code) {
                setResult(emsreadingData.message);
                return
            }

            const emsmetersData = emsmetersremovalprotection(project.meters, id, 'clients');
            if (emsmetersData.code) {
                setResult(emsmetersData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'clients');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: clientid
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECLIENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove client data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CLIENT',
                            id: clientid
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeClient(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const sortBalances = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    return (
        <div className='clients'>
            <Title text='Clients' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Client</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewClientsStatus(project.clients)}>View Clients Status</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={copyButton}>Copy as Text</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Name</th>
                        <th>
                            Balance
                            <div className='table_sortbutton' onClick={sortBalances}>
                                {order === 'asc' ? ' ↓' : ' ↑'}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredclients.map((client, index) => {
                            let clientBalanceLoc = client.balance.toLocaleString('en-US', { minimumFractionDigits: Number.isInteger(client.balance) ? 0 : 2, maximumFractionDigits: 2 });

                            return (
                                <tr key={'client' + index}>
                                    <td><div className='table_button' onClick={() => editButton(client)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(client)}>View</div></td>
                                    <td>
                                        <div>{client.name}</div>
                                    </td>
                                    <td>
                                        <div
                                            className='clients_balance'
                                            onClick={() => handleAccountStatement('clients', { id: client.id, currencyid: project.basecurrency, name: client.name }, setOption)}
                                        >
                                            {client.currency} {clientBalanceLoc}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div >
    );
}

export default Clients;