import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import Title from './Title.js';
import { numberToWords } from './Utils/textparse.js';

import Header from './Header.js';

import './CollectionsOverview.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

//20240525

function CollectionsOverview({ state, setState }) {
    const [{ projects }, dispatch] = useStateValue();

    const printRef = useRef();
    const [collections, setCollections] = useState([]);
    const [option, setOption] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchCollections = () => {
            const uniqueIds = projects.map(project => project.userid).filter((userId, index, array) => array.indexOf(userId) === index);
            console.log('Trying to get collections overview');
            setLoading(true);

            if (uniqueIds.length > 0) {
                setCollections([]);
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETCOLLECTIONSOVERVIEW, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Collections overview data received');
                        console.log(res.data);
                        if (res.data instanceof Array) {
                            setCollections(prevEntries => [...prevEntries, ...res.data]);
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                });
            }
        };

        fetchCollections();
        const interval = setInterval(fetchCollections, CHECKINTERVAL);
        return () => clearInterval(interval);
    }, [refresh]);

    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    const handleClick = (collection) => {
        setOption(<Modal collection={collection} setOption={() => setOption(null)} />);
    };

    function Modal({ collection, setOption }) {
        const [projectuserid, setProjectuserid] = useState('');
        const [projectid, setProjectid] = useState('');
        const [id, setId] = useState('');

        const [modalloading, setModalloading] = useState(false);
        const [modalresult, setModalresult] = useState(null);

        const modalRef = useRef();
        const printReceiptRef = useRef();
        const printReceiptButtonRef = useRef();

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setOption(null);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [setOption]);

        useEffect(() => {
            setProjectuserid(collection.projectuserid)
            setProjectid(collection.projectid)
            setId(collection.id)
        }, [collection])

        const markCollection = (status) => {
            console.log('Trying to mark collection');
            setModalloading(true)

            const data = {
                projectuserid: projectuserid,
                projectid: projectid,
                id: id,
                status: status
            };

            axios.post(configData.CONTROLLERURL + configData.MARKCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Mark collection data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setOption(null)
                        setRefresh(!refresh)
                        if (status == 'received') printReceiptButtonRef.current.handlePrint();
                    }
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setModalloading(false);
            });
        };

        const handleShare = () => {
            const projectName = projects.find(project => project.projectid === collection.projectid)?.name || 'Project';
            const collectorName = collection.collectorname ? collection.collectorname : '';
            const receiptText =
                "<100>COPY FOR CLIENT\n" +
                "<000>Receipt ID     : " + collection.id + "\n" +
                "<000>Project Name   : " + projectName + "\n" +
                "\n" +
                "<110>Client Information\n" +
                "<000>Client Name    : " + collection.clientname + "\n" +
                "\n" +
                "<110>Payment Details\n" +
                "<000>Description    : " + collection.description + "\n" +
                "<000>Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                "<000>Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencysymbol + "\n" +
                "\n" +
                "<110>Collector Information\n" +
                "<000>Collector      : " + collectorName + "\n" +
                "\n" +
                "<000>Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                "\n" +
                "<100>Thank you for your payment!\n" +
                "\n";

            if (navigator.share) {
                navigator.share({
                    title: 'Receipt',
                    text: receiptText,
                }).then(() => {
                    markCollection('received')
                }).catch((error) => {
                    console.error('Error sharing:', error)
                });

            } else {
                alert('Web Share API is not supported in your browser.');
            }
        };

        return (
            <div className='collectionsoverview_modal_overlay'>
                <div className='collectionsoverview_modal' ref={modalRef}>
                    <div className='collectionsoverview_modalheader'>Receipt</div>
                    <div className='collectionsoverview_printable' ref={printReceiptRef}>
                        <pre className='collectionsoverview_printablepre'>
                            {"COPY FOR CLIENT\n" +
                                "Receipt ID     : " + collection.id + "\n" +
                                "Project Name   : " + projects.find(project => project.projectid === collection.projectid)?.name + "\n" +
                                "\n" +
                                "Client Information\n" +
                                "Client Name    : " + collection.clientname + "\n" +
                                "\n" +
                                "Payment Details\n" +
                                "Description    : " + collection.description + "\n" +
                                "Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                                "Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencysymbol + "\n" +
                                "\n" +
                                "Collector Information\n" +
                                "Collector      : " + (collection.collectorname ? collection.collectorname : '') + "\n" +
                                "\n" +
                                "Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                                "\n" +
                                "Thank you for your payment!\n"}
                        </pre>
                    </div>
                    <div className='collectionsoverview_modalstatusreceived' onClick={() => markCollection('received')}>Received - استلام</div>
                    {modalresult && <div className='collectionsoverview_modalresult'>{modalresult}</div>}
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className="collectionsoverview_button" onClick={handleShare}>Share</div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='collectionsoverview_button' onClick={() => setOption(null)}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const totalsByCurrency = [];
    collections.forEach(collection => {
        if (collection.status === 'received') {
            const currencyTotal = totalsByCurrency.find(total => total.currency === collection.currencysymbol);
            if (currencyTotal) {
                currencyTotal.amount += parseInt(collection.amount, 10);
            } else {
                totalsByCurrency.push({
                    currency: collection.currencysymbol,
                    amount: parseInt(collection.amount, 10)
                });
            }
        }
    });

    return (
        <div className='collectionsoverview'>
            <Header state={state} setState={setState} />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <ReactToPrint
                        trigger={() => (
                            <button className="button" onClick={handlePrint}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                </div>
            </div>

            <div className='collectionsoverview_printable' ref={printRef}>
                <Title text='COLLECTIONS OVERVIEW' />
                <table className='collectionsoverview_table'>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>CollectionDate</th>
                            <th>ProjectName</th>
                            <th>ClientName</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan={7}><div className='collectionsoverview_loading'>loading...</div></td></tr>}
                        {
                            collections.map((collection, index) => {
                                return (
                                    <tr key={'collection' + index} className='collectionsoverview_collection' onClick={() => handleClick(collection)}>
                                        <td><div>{moment.unix(collection.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                        <td><div>{moment.unix(collection.atts).format('D/MMM/YYYY')}</div></td>
                                        <td><div>{projects.find(project => project.projectid === collection.projectid)?.name}</div></td>
                                        <td><div>{collection.clientname}</div></td>
                                        <td><div>{collection.currencysymbol} {collection.amount}</div></td>
                                        <td><div>{collection.note}</div></td>
                                        {collection.status === 'pending' && <td><div className='collectionsoverview_statuspending'>{collection.status}</div></td>}
                                        {collection.status === 'received' && <td><div className='collectionsoverview_statusreceived'>{collection.status}</div></td>}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        {totalsByCurrency.map(({ currency, amount }) => (
                            <tr key={currency}>
                                <td colSpan={4}></td>
                                <td>
                                    <div>
                                        Total Received: {currency + amount.toLocaleString()}
                                    </div>
                                </td>
                                <td colSpan={2}></td>
                            </tr>
                        ))}
                    </tfoot>
                </table>
                {option}
            </div>
        </div>
    );
}

export default CollectionsOverview;