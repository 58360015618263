import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import Title from './Title.js';
import { numberToWords } from './Utils/textparse.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Header from './Header.js';

import './PaymentsOverview.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

//20240525

function PaymentsOverview({ state, setState }) {
    const [{ projects }, dispatch] = useStateValue();

    const printRef = useRef();
    const [payments, setPayments] = useState([]);
    const [option, setOption] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPaymentsOverview = () => {
            const uniqueIds = projects.map(project => project.userid).filter((userId, index, array) => array.indexOf(userId) === index);

            console.log('Trying to get payments overview');
            setLoading(true);

            if (uniqueIds.length > 0) {
                setPayments([]);
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETPAYMENTSOVERVIEW, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Payments overview data received');
                        console.log(res.data);
                        if (res.data instanceof Array) {
                            setPayments(prevEntries => [...prevEntries, ...res.data]);
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                });
            }
        };

        fetchPaymentsOverview();
        const intervalId = setInterval(fetchPaymentsOverview, CHECKINTERVAL);
        return () => clearInterval(intervalId);
    }, [refresh, projects]);

    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    const handleClick = (payment) => {
        setOption(<Modal payment={payment} setOption={() => setOption(null)} />);
    };

    function Modal({ payment, setOption }) {
        const [projectuserid, setProjectuserid] = useState('');
        const [projectid, setProjectid] = useState('');
        const [id, setId] = useState('');

        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);

        const [modalloading, setModalloading] = useState(false);
        const [modalresult, setModalresult] = useState(null);

        const modalRef = useRef();

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setOption(null);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [setOption]);

        useEffect(() => {
            setProjectuserid(payment.projectuserid)
            setProjectid(payment.projectid)
            setId(payment.id)
            setAt(new Date(payment.atts * 1000))
        }, [payment])

        const markPayment = (status, atts) => {
            console.log('Trying to mark payment');
            setModalloading(true)

            const data = {
                projectuserid: projectuserid,
                projectid: projectid,
                id: id,
                status: status,
                atts: atts
            };

            axios.post(configData.CONTROLLERURL + configData.MARKPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Mark payment data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setOption(null)
                        setRefresh(!refresh)
                    }
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setModalloading(false);
            });
        };

        return (
            <div className='paymentsoverview_modal_overlay'>
                <div className='paymentsoverview_modal' ref={modalRef}>
                    <div className='paymentsoverview_modalheader'>Payment</div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={at}
                                onChange={(date) => setAt(date)}
                            />
                        </div>
                    </div>
                    <div className='paymentsoverview_modalstatuspending' onClick={() => markPayment('pending', atts)}>Pending</div>
                    <div className='paymentsoverview_modalstatuspaid' onClick={() => markPayment('paid', atts)}>Paid</div>
                    <div className='paymentsoverview_modalstatusscheduled' onClick={() => markPayment('scheduled', atts)}>Scheduled</div>
                    {modalresult && <div className='paymentsoverview_modalresult'>{modalresult}</div>}
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='paymentsoverview_button' onClick={() => setOption(null)}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='paymentsoverview'>
            <Header state={state} setState={setState} />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <ReactToPrint
                        trigger={() => (
                            <button className="button" onClick={handlePrint}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                </div>
            </div>

            <div className='paymentsoverview_printable' ref={printRef}>
                <Title text='PAYMENTS OVERVIEW' />
                <table className='paymentsoverview_table'>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>PaymentDate</th>
                            <th>ProjectName</th>
                            <th>SupplierName</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan={7}><div className='paymentsoverview_loading'>loading...</div></td></tr>}
                        {
                            payments.map((payment, index) => {
                                return (
                                    <tr key={'payment' + index} className='paymentsoverview_payment' onClick={() => handleClick(payment)}>
                                        <td><div>{moment.unix(payment.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                        <td><div>{moment.unix(payment.atts).format('D/MMM/YYYY')}</div></td>
                                        <td><div>{projects.find(project => project.projectid === payment.projectid)?.name}</div></td>
                                        <td><div>{payment.suppliername}</div></td>
                                        <td><div>{payment.currencysymbol} {payment.amount}</div></td>
                                        <td><div>{payment.note}</div></td>
                                        {payment.status === 'pending' && <td><div className='paymentsoverview_statuspending'>{payment.status}</div></td>}
                                        {payment.status === 'paid' && <td><div className='paymentsoverview_statuspaid'>{payment.status}</div></td>}
                                        {payment.status === 'scheduled' && <td><div className='paymentsoverview_statusscheduled'>{payment.status}</div></td>}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {option}
            </div>
        </div>
    );
}

export default PaymentsOverview;