import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import SupplierSelect from './Components/supplierselect.js';
import CurrencySelect from './Components/currencyselect.js';
import Title from './Title.js';
import ReactToPrint from 'react-to-print';

import './Payments.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

function Payments({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [payments, setPayments] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchPayments = () => {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid
            }

            axios.post(configData.CONTROLLERURL + configData.GETPAYMENTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get payments data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setPayments(res.data)
                }
            }).catch((error) => {
                console.error(error);
            });
        }

        fetchPayments();
        const intervalId = setInterval(fetchPayments, CHECKINTERVAL);
        return () => clearInterval(intervalId);
    }, [project]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddPayment />)
    }

    const editButton = (payment) => {
        setOption(<EditPayment payment={payment} />)
    }

    const removeButton = (payment) => {
        setOption(<RemoveCollection payment={payment} />)
    }

    const viewButton = (payment) => {
        setOption(<ViewPayment payment={payment} />)
    }

    function AddPayment() {
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [supplierid, setSupplierid] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [note, setNote] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const addPayment = (atts, supplierid, note, amount, currencyid) => {
            console.log('Trying to add collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Payments', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const supplierName = project.suppliers.find((supplier) => supplierid == supplier.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                atts: atts,
                supplierid: supplierid,
                suppliername: supplierName,
                note: trimmedNote,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add payment data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        data.status = 'pending';
                        setPayments([...payments, data]);
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Payment</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={at}
                                    onChange={(date) => setAt(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Note (for payer)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addPayment(atts, supplierid, note, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function EditPayment({ payment }) {
        const [id, setId] = useState('');

        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [note, setNote] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(payment.id);
            setAt(new Date(payment.atts * 1000))
            setAtts(payment.atts);
            setSupplierid(payment.supplierid);
            setNote(payment.note);
            setAmount(payment.amount);
            setCurrencyid(payment.currencyid);
        }, [payment]);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const updatePayment = (id, atts, supplierid, note, amount, currencyid) => {
            console.log('Trying to update collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Payments', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const supplierName = project.suppliers.find((supplier) => supplierid == supplier.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                atts: atts,
                supplierid: supplierid,
                suppliername: supplierName,
                note: trimmedNote,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newPayments = payments.map(payment =>
                            payment.id === data.id ? data : payment
                        )
                        setPayments(newPayments)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Payment</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={at}
                                    onChange={(date) => setAt(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Note (for payer)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updatePayment(id, atts, supplierid, note, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(payment)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewPayment({ payment }) {
        const printRef = useRef();

        const [id, setId] = useState('');
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [supplierid, setSupplierid] = useState('');
        const [note, setNote] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        useEffect(() => {
            setId(payment.id)
            setAt(new Date(payment.at * 1000));
            setAtts(payment.atts);
            setSupplierid(payment.supplierid);
            setNote(payment.note);
            setAmount(payment.amount);
            setCurrencyid(payment.currencyid);
        }, [payment]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>View Payment</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Date</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {moment.unix(atts).format('D/MMM/YYYY')}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Supplier</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {project.suppliers.find((supplier) => supplier.id === supplierid)?.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Note (for payer)</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {note}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Amount</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {payment.amount} {project.currencies.find((currency) => currency.id === payment.currencyid)?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveCollection({ payment }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(payment.id);
        }, [payment]);

        const removePayment = (id) => {
            console.log('Trying to remove payment');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Payments', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEPAYMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove payment data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newPayments = payments.filter(payment => payment.id !== id)
                        setPayments(newPayments)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removePayment(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='payments'>
            <Title text='Payments' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Payment</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Created</th>
                        <th>Payment Date</th>
                        <th>Supplier</th>
                        <th>Note</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        payments.map((payment, index) => {
                            const supplierName = project.suppliers.find((supplier) => supplier.id === payment.supplierid)?.name;
                            const currencyName = project.currencies.find((currency) => currency.id === payment.currencyid)?.name;
                            return (
                                <tr key={'payment' + index}>
                                    <td><div className='table_button' onClick={() => editButton(payment)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(payment)}>View</div></td>
                                    <td><div>{moment.unix(payment.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{moment.unix(payment.atts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{payment.note}</div></td>
                                    <td><div>{payment.amount} {currencyName}</div></td>
                                    {payment.status === 'pending' && <td><div className='collections_statuspending'>{payment.status}</div></td>}
                                    {payment.status === 'received' && <td><div className='collections_statusreceived'>{payment.status}</div></td>}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {option}
        </div>
    );
}

export default Payments;