import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import SubaccountSelect from './Components/subaccountselect.js';
import CashSelect from './Components/cashselect.js';
import ClientSelect from './Components/clientselect.js';
import CurrencySelect from './Components/currencyselect.js';
import Title from './Title.js';
import ReactToPrint from 'react-to-print';

import './Collections.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

function Collections({ state, setState }) {
    const [{ user, project }, dispatch] = useStateValue();

    const [collections, setCollections] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCollections = () => {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid
            };

            axios.post(configData.CONTROLLERURL + configData.GETCOLLECTIONS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get collections data received');
                console.log(res.data);
                if (res.data instanceof Array) {
                    setCollections(res.data);
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
            });
        };

        fetchCollections();
        const interval = setInterval(fetchCollections, CHECKINTERVAL);
        return () => clearInterval(interval);
    }, [project]);


    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddCollection />)
    }

    const editButton = (collection) => {
        setOption(<EditCollection collection={collection} />)
    }

    const covertToTransaction = (collection, transaction) => {
        setOption(<ConvertTransaction collection={collection} transaction={transaction} />)
    }

    const removeButton = (collection) => {
        setOption(<RemoveCollection collection={collection} />)
    }

    const viewButton = (collection) => {
        setOption(<ViewCollection collection={collection} />)
    }

    function AddCollection() {
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [clientid, setClientid] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const addCollection = (atts, clientid, note, description, amount, currencyid) => {
            console.log('Trying to add collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!clientid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const clientName = project.clients.find((client) => clientid == client.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                atts: atts,
                clientid: clientid,
                clientname: clientName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add colleciton data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        data.status = 'pending';
                        setCollections([...collections, data]);
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Collection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={at}
                                    onChange={(date) => setAt(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={clientid} setClientid={setClientid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Note (for collector)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addCollection(atts, clientid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function EditCollection({ collection }) {
        const [id, setId] = useState('');

        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);

        const [clientid, setClientid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(collection.id);
            setAt(new Date(collection.atts * 1000))
            setAtts(collection.atts);
            setClientid(collection.clientid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        useEffect(() => {
            setAtts(Math.floor(new Date(at).getTime() / 1000));
        }, [at]);

        const updateCollection = (id, atts, clientid, note, description, amount, currencyid) => {
            console.log('Trying to update collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!clientid || amount == 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedNote = note.trim();
            const trimmedDescription = description.trim();
            const clientName = project.clients.find((client) => clientid == client.id)?.name;
            const currencySymbol = project.currencies.find((currency) => currencyid == currency.id)?.symbol;

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                atts: atts,
                clientid: clientid,
                clientname: clientName,
                note: trimmedNote,
                description: trimmedDescription,
                amount: amount,
                currencyid: currencyid,
                currencysymbol: currencySymbol
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.map(collection =>
                            collection.id === data.id ? data : collection
                        )
                        setCollections(newCollections)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Collection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={at}
                                    onChange={(date) => setAt(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={clientid} setClientid={setClientid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Note (for collector)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateCollection(id, atts, clientid, note, description, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(collection)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ConvertTransaction({ collection, transaction }) {
        const [collectionid, setCollectionid] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setCollectionid(collection.collectionid);
            setName(transaction.name);
            setSelectedclient(transaction.clientid);
            setSelectedaccount(transaction.selectedaccount);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid)
        }, [transaction]);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addReceipt = (ts, name, from, subaccount, to, amount, currencyid) => {
            console.log('Trying to add transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedclient || !subaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccount: subaccount,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        removeCollection(collectionid)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const removeCollection = (id) => {
            console.log('Trying to remove collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.filter(collection => collection.id !== id)
                        setCollections(newCollections)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Receipt</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={selectedclient} setClientid={(clientId) => setSelectedclient(clientId)} width={300} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Cash Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CashSelect cashaccounts={project.cashaccounts} accountid={selectedaccount} setAccountid={setSelectedaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addReceipt(timestamp, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function ViewCollection({ collection }) {
        const printRef = useRef();

        const [id, setId] = useState('');
        const [at, setAt] = useState(new Date());
        const [atts, setAtts] = useState(0);
        const [clientid, setClientid] = useState('');
        const [note, setNote] = useState('');
        const [description, setDescription] = useState('');
        const [amount, setAmount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        useEffect(() => {
            console.log(collection)
            setId(collection.id)
            setAt(new Date(collection.at * 1000));
            setAtts(collection.atts);
            setClientid(collection.clientid);
            setNote(collection.note);
            setDescription(collection.description);
            setAmount(collection.amount);
            setCurrencyid(collection.currencyid);
        }, [collection]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>View Collection</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Date</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {moment.unix(collection.atts).format('D/MMM/YYYY')}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Client</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {project.clients.find((client) => client.id === collection.clientid)?.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Note</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {note}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Description</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {description}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Amount</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {collection.amount} {project.currencies.find((currency) => currency.id === collection.currencyid)?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={() => covertToTransaction({ collectionid: id }, { ts: '', name: description, clientid: clientid, subaccount: '', cashaccounts: project.cashaccounts, amount: amount, currencyid: currencyid })}>Convert to transaction</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveCollection({ collection }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(collection.id);
        }, [collection]);

        const removeCollection = (id) => {
            console.log('Trying to remove collection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Collections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove collection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        let newCollections = collections.filter(collection => collection.id !== id)
                        setCollections(newCollections)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeCollection(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='collections'>
            <Title text='Collections' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Collection</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Created</th>
                        <th>Collection Date</th>
                        <th>Client</th>
                        <th>Note</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        collections.map((collection, index) => {
                            const clientName = project.clients.find((client) => client.id === collection.clientid)?.name;
                            const currencyName = project.currencies.find((currency) => currency.id === collection.currencyid)?.name;
                            return (
                                <tr key={'collection' + index}>
                                    <td><div className='table_button' onClick={() => editButton(collection)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(collection)}>View</div></td>
                                    <td><div>{moment.unix(collection.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{moment.unix(collection.atts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{clientName}</div></td>
                                    <td><div>{collection.note}</div></td>
                                    <td><div>{collection.amount} {currencyName}</div></td>
                                    {collection.status === 'pending' && <td><div className='collections_statuspending'>{collection.status}</div></td>}
                                    {collection.status === 'received' && <td><div className='collections_statusreceived'>{collection.status}</div></td>}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {option}
        </div>
    );
}

export default Collections;